import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";

// Register components for Bar and Line Charts
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const SuccessRefusalChart = ({ data }) => {
  const monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const dayNumbers = [1, 2, 3, 4, 5, 6, 7];
  //const [weekNumbers, setWeekNumbers] = useState([1, 2, 3, 4]);
  // Extract labels from the data
  const temp = data?.weekOrDaysOrMonths || [];
  let labels;
  if (temp.length === 12) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Convert month numbers to month names
    labels = temp.map((num) => months[monthNumbers.indexOf(num)]);
  } else if (temp.length === 7) {
    const days = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
    labels = dayNumbers.map((num) => days[num - 1]);
  } else {
    labels = temp.map((num) => `Week-${num}`);
  }

  // Extract data for successRate and refusalRate
  const successRate = data?.success || [];
  const refusalRate = data?.refusal || [];

  // Define datasets for the chart
  const datasets = [
    {
      type: "bar",
      label: "Success Rate (%)",
      data: successRate,
      backgroundColor: "#5CA4A9",
      borderColor: "#5CA4A9",
      borderWidth: 1,
      yAxisID: "y",
    },
    {
      type: "bar",
      label: "Refusal Rate (%)",
      data: refusalRate,
      backgroundColor: "#A9E3D5",
      borderColor: "#A9E3D5",
      borderWidth: 1,
      yAxisID: "y",
    },
    {
      type: "line",
      label: "Success Growth (%)",
      data: successRate,
      borderColor: "#388e3c",
      backgroundColor: "rgba(56, 142, 60, 0.5)",
      fill: false,
      tension: 0.4,
      yAxisID: "y1",
      borderWidth: 1,
    },
  ];

  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Success and Refusal Rate By Calls Completed",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 20,
        },
        title: {
          display: false,
          text: "Calls Completed",
        },
      },
      y1: {
        beginAtZero: true,
        max: 100,
        position: "right",
        ticks: {
          display: false,
          stepSize: 20,
        },
        title: {
          display: false,
          text: "Success Rate (%)",
        },
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default SuccessRefusalChart;
