// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
}

.graph-dashboard-container {
  background-color: #e3edf4;
}

.graph-header-container {
  display: flex;
  justify-content: space-between;
}

.graph-header-icon {
  align-content: center;
}

.graph-header-item {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  text-align: center;
}

.graph-header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/AdminDashboard/Graphs/adminGraphs.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;AACd","sourcesContent":["* {\r\n  padding: 0;\r\n}\r\n\r\n.graph-dashboard-container {\r\n  background-color: #e3edf4;\r\n}\r\n\r\n.graph-header-container {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.graph-header-icon {\r\n  align-content: center;\r\n}\r\n\r\n.graph-header-item {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  padding: 16px;\r\n  text-align: center;\r\n}\r\n\r\n.graph-header-content {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n  padding: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
