import { useEffect, useState } from "react";
import { getCoderLeadDashboardData } from "../../../api/graphsApi";

export const useCoderData = (param) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState({
    schedulerLoading: true,
    callLogLoading: true,
  });

  useEffect(() => {
    if (!param?.planId || !param?.year) return;

    const fetchSchedulerData = async () => {
      try {
        setLoading((prev) => ({ ...prev, schedulerLoading: true }));
        const response = await getCoderLeadDashboardData(param);
        const DashData = response?.data?.data;

        if (typeof DashData === "object") {
          const progData = {
            unaasigned: DashData["Unassigned Members"],
          };
          const headerCardsData = {
            fromClin: DashData["Total Charts Came from Clinician Review"],
            assigned: DashData["Total Charts Assigned"],
            completed: DashData["Total Charts Complete"],
            toClin: DashData["Total Charts Out for Clinician Review"],
          };
          const avgDaysData = [
            {
              name: "Ready for Coders",
              count:
                Math.ceil(
                  DashData["Averages for Number of Days In ReadyForCoding"]
                    ?.averageDifference,
                ) ?? 0,
              color: "#36A2EB",
            },
            {
              name: "Clinician Review",
              count:
                Math.ceil(
                  DashData["Averages for Number of Days In Clinician Review"]
                    ?.averageDifference,
                ) ?? 0,
              color: "#FF9F40",
            },
            {
              name: "Ready for Upload",
              count:
                Math.ceil(
                  DashData["Averages for Number of Days Ready For Upload"]
                    ?.averageDifference,
                ) ?? 0,
              color: "#FFCD46",
            },
          ];

          const assignedNotStarted = [
            {
              name: "Started",
              count:
                DashData["Total Charts Assigned"] -
                DashData["Total Charts Not Started"],
              color: "#4BC0C0",
            },
            {
              name: "Not Started",
              count: DashData["Total Charts Not Started"],
              color: "#FF9F40",
            },
          ];

          const topCoderByAssignedMem = DashData[
            "Top Coder By CoderAssigned"
          ].map((cod) => ({
            name: `${cod.firstName}`,
            //name: `${qa.firstName} ${qa.lastName}`,
            count: cod.totalmember,
          }));
          const topCoderByReviewCount = DashData[
            "Top Coder By CoderReviewCount"
          ].map((cod) => ({
            name: `${cod.firstName}`,
            //name: `${qa.firstName} ${qa.lastName}`,
            count: cod.totalcoderReviewCount,
          }));
          setData((prev) => ({
            ...prev,
            progData,
            headerCardsData,
            avgDaysData,
            assignedNotStarted,
            topCoderByAssignedMem,
            topCoderByReviewCount,
          }));
        }
      } catch (error) {
        console.error("Error fetching Coder data:", error);
      } finally {
        setLoading((prev) => ({ ...prev, schedulerLoading: false }));
      }
    };

    fetchSchedulerData();
  }, [param]);

  return { data, loading };
};
