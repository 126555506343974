import React from "react";
import { Pie } from "react-chartjs-2";

function StatusAvgDays({ data }) {
  console.log("!!", data);
  if (
    (Object.keys(data)?.length === 3 &&
      data?.readyForQa?.count === 0 &&
      data?.clinReview?.count === 0 &&
      data?.readyForUpload?.count === 0) ||
    (Object.keys(data)?.length === 2 &&
      data?.readyForQa?.count === 0 &&
      data?.clinReview?.count === 0)
  )
    return <h3>No Data Available</h3>;

  const pieData = {
    labels:
      Object.keys(data).length === 3
        ? ["Ready for Coding", "Clinician Review", "Ready for Upload"]
        : ["Ready for Coding", "Clinician Review"],
    datasets: [
      {
        data: [
          data?.readyForQa?.count,
          data?.clinReview?.count,
          data.readyForUpload?.count,
        ], // Example data
        backgroundColor: ["#36A2EB", "#FF9F40", "#FFCD46"],
        radius: "70%",
        hoverOffset: 20,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };
  return <Pie data={pieData} options={options} />;
}

export default StatusAvgDays;
