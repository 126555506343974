import axiosWrapper from "./axiosWrapper";

export const getSchedulersData = (data) =>
  axiosWrapper.post("/users/get-users-by-roleId", data);

export const getHraStatus = () => axiosWrapper.get("/hra-status");

export const getLobData = () => axiosWrapper.get("/lobTypes/list");

export const getClientByID = (id) => axiosWrapper.get(`/clients/view?id=${id}`);

export const getClientsData = () => axiosWrapper.get("/clients/list");
