// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
}

.graph-dashboard-container {
  background-color: #e3edf4;
}

.graph-header-container {
  display: flex;
  justify-content: space-between;
}

.graph-header-icon {
  align-content: center;
}

.graph-header-item {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  text-align: center;
}

.graph-header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
}

.graph-chart-item {
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.graph-hra-status {
  /* Additional styles for HRA Status chart */
  border-radius: 8px;
  height: 100%;
}

.status-days h6,
.top-review-count h6,
.assigned-status h6,
.top-mem-agn h6 {
  background-color: #d0e6f5;
  text-align: left;
  padding: 1%;
  border-radius: 8px 8px 0px 0px;
  font-weight: 700;
  font-size: 14px;
}

.status-days,
.top-review-count,
.assigned-status,
.top-mem-agn {
  border-radius: 8px;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/QaDashboard/Graphs/qaGraphs.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,wCAAwC;AAC1C;;AAEA;EACE,2CAA2C;EAC3C,kBAAkB;EAClB,YAAY;AACd;;AAEA;;;;EAIE,yBAAyB;EACzB,gBAAgB;EAChB,WAAW;EACX,8BAA8B;EAC9B,gBAAgB;EAChB,eAAe;AACjB;;AAEA;;;;EAIE,kBAAkB;EAClB,YAAY;AACd","sourcesContent":["* {\r\n  padding: 0;\r\n}\r\n\r\n.graph-dashboard-container {\r\n  background-color: #e3edf4;\r\n}\r\n\r\n.graph-header-container {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.graph-header-icon {\r\n  align-content: center;\r\n}\r\n\r\n.graph-header-item {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  padding: 16px;\r\n  text-align: center;\r\n}\r\n\r\n.graph-header-content {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n  padding: 8px;\r\n}\r\n\r\n.graph-chart-item {\r\n  padding: 16px;\r\n  background-color: #fff;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.graph-hra-status {\r\n  /* Additional styles for HRA Status chart */\r\n  border-radius: 8px;\r\n  height: 100%;\r\n}\r\n\r\n.status-days h6,\r\n.top-review-count h6,\r\n.assigned-status h6,\r\n.top-mem-agn h6 {\r\n  background-color: #d0e6f5;\r\n  text-align: left;\r\n  padding: 1%;\r\n  border-radius: 8px 8px 0px 0px;\r\n  font-weight: 700;\r\n  font-size: 14px;\r\n}\r\n\r\n.status-days,\r\n.top-review-count,\r\n.assigned-status,\r\n.top-mem-agn {\r\n  border-radius: 8px;\r\n  height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
