import React from "react";
import { Pie } from "react-chartjs-2";
import "./pieChart.css";

const AdminPieChart = ({ data }) => {
  const val = data?.map((dt) => dt.count);
  if (val?.every((count) => count === 0)) return <h3>No Available Data</h3>;
  const lab = data?.map((dt) => dt.name);
  const col = data?.map((dt) => dt.color);
  const pieData = {
    labels: lab,
    datasets: [
      {
        data: val, // Example data
        backgroundColor: col,
        radius: "70%",
        hoverBackgroundColor: col,
        hoverOffset: 20,
        borderWidth: 0,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      tooltip: {
        display: false,
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };
  return (
    <div className="chart-container">
      <Pie data={pieData} options={options} />
      {/* Scrollable Status Fields */}
      <div className="status-container">
        <div className="status-wrapper">
          {lab?.map((label, index) => (
            <span key={index} className="status-item">
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  backgroundColor: col[index],
                  marginRight: "5px",
                }}
              ></span>
              {`${label}: ${val[index].toFixed(0)}`}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminPieChart;
