import { Clear, ErrorOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import React, { useEffect, useState } from "react";

function FilterModal({
  open,
  onClose,
  onFilterChange,
  setGraphFilter,
  hra,
  users,
  lob,
  prog,
  filters,
  setFilters,
  coder,
  setCoder,
}) {
  const roleId = sessionStorage.getItem("roleId");
  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");

  const t = users?.filter((user) => user.name === firstName + " " + lastName);
  useEffect(() => {
    if (roleId == 9) {
      setCoder(t[0]?.id);
    }
  }, [roleId, setCoder, t]);

  const handleReset = () => {
    const updatedFilters = {
      program: "",
      lob: "",
      hrastatus: "",
      fromDate: null,
      toDate: null,
    };
    setFilters(updatedFilters);
    setCoder("");
  };

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [name]: value,
      };
      return updatedFilters;
    });
  };

  const [dateError, setDateError] = useState("");

  const handleDateChange = (name, value) => {
    const newDate = value ? moment(value).toDate() : null;

    // Check the date validation
    if (name === "fromDate" && filters.toDate && newDate > filters.toDate) {
      setDateError("From date cannot be later than To date");
    } else if (
      name === "toDate" &&
      filters.fromDate &&
      newDate < filters.fromDate
    ) {
      setDateError("To date cannot be earlier than From date");
    } else {
      setDateError(""); // Clear the error if the validation passes
    }
    setFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [name]: value ? moment(value).toDate() : null,
      };
      return updatedFilters;
    });
  };

  const convertDateForm = (date) => {
    return date ? moment(date).format("YYYY-MM-DD") : "";
  };

  const handleApplyFilters = (updatedFilters, isReset) => {
    if (updatedFilters.fromDate || updatedFilters.toDate) {
      if (
        updatedFilters.fromDate &&
        updatedFilters.toDate &&
        moment(updatedFilters.fromDate).isAfter(updatedFilters.toDate)
      ) {
        setDateError("From date cannot be later than To date");
        return; // Prevent applying the filters if the dates are invalid
      } else if (!updatedFilters.fromDate || !updatedFilters.toDate) {
        setDateError("Date range not matched");
        return;
      } else {
        setDateError(""); // Clear the error
      }
    }

    if (isReset) {
      onFilterChange([], "", true);
    } else {
      if (updatedFilters?.fromDate) {
        updatedFilters.fromDate = convertDateForm(updatedFilters?.fromDate);
      }

      if (updatedFilters?.toDate) {
        updatedFilters.toDate = convertDateForm(updatedFilters?.toDate);
      }

      const newFilterOptions = [
        updatedFilters?.lob && {
          fieldName: "assessmentData.demographics.data.lob",
          fieldValue: updatedFilters?.lob.toString(),
        },
        updatedFilters?.fromDate && {
          fieldName: "DOSDateFrom",
          fieldValue: updatedFilters?.fromDate.toString(),
        },
        updatedFilters?.toDate && {
          fieldName: "DOSDateTo",
          fieldValue: updatedFilters?.toDate.toString(),
        },
        updatedFilters?.hrastatus && {
          fieldName: "hraStatus.hraStatus",
          fieldValue: updatedFilters?.hrastatus.toString(),
        },
        updatedFilters?.program && {
          fieldName: "program",
          fieldValue: updatedFilters?.program.toString(),
        },
      ].filter(Boolean);
      onFilterChange(newFilterOptions, coder, false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="filter-modal-title"
      aria-describedby="filter-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "57%",
          height: "auto",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // This ensures the items are spaced
            alignItems: "center", // Vertically align the content
            bgcolor: (theme) => `${theme.palette.primary.main}`,
            color: (theme) => `${theme.palette.primary.contrastText}`,
            p: 2,
            textAlign: "left",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            width: "100%",
          }}
        >
          <Typography variant="h6" component="h2" id="filter-modal-title">
            Filter
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: (theme) => `${theme.palette.primary.contrastText}`,
              ml: 2, // Add margin to the left to separate from the heading
              display: "flex",
            }}
          >
            <ErrorOutlined
              sx={{ marginRight: "3px", height: "18px", width: "18px" }}
            />
            Filter is not applicable for splittable data
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            p: 4,
            pt: 2,
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            alignItems: "center",
          }}
        >
          {/* Date Pickers */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Evaluation From"
              value={filters?.fromDate ? moment(filters.fromDate) : null}
              onChange={(newValue) => {
                handleDateChange("fromDate", newValue);
              }}
              maxDate={moment()}
              sx={{
                border: (theme) => `1px solid ${theme.palette.primary.main}`,
                borderRadius: 1,
                flex: "1 1 calc(50% - 16px)",
              }}
              error={!!dateError} // Show error if the date is invalid
              helperText={dateError} // Show error message
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="normal" />
              )}
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, 10], // Slight adjustment of the popper's position
                    },
                  },
                ],
                sx: {
                  zIndex: 1500, // Ensure the date picker appears on top of other elements
                },
              }}
              componentsProps={{
                actionBar: {
                  actions: ["clear"], // Enable the clear button in the action bar
                },
              }}
              PaperComponent={({ children }) => (
                <Grid container direction="column">
                  <Grid item>{children}</Grid>
                  <Grid item>
                    <Box
                      display="flex"
                      justifyContent="center"
                      sx={{
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        right: "0",
                      }}
                    >
                      {/* Clear button below the date picker */}
                      <Button
                        variant="outlined"
                        startIcon={<Clear />}
                        onClick={() =>
                          setFilters((prevFilters) => {
                            const updatedFilters = {
                              ...prevFilters,
                              ["fromDate"]: null,
                            };
                            return updatedFilters;
                          })
                        }
                        sx={{ marginTop: 1, marginBottom: 2 }}
                      >
                        Clear Date
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Evaluation To"
              value={filters?.toDate ? moment(filters.toDate) : null}
              onChange={(newValue) => {
                handleDateChange("toDate", newValue);
              }}
              maxDate={moment()}
              sx={{
                border: (theme) => `1px solid ${theme.palette.primary.main}`,
                borderRadius: 1,
                flex: "1 1 calc(50% - 16px)",
              }}
              error={!!dateError} // Show error if the date is invalid
              helperText={dateError} // Show error message
              renderInput={(params) => (
                <TextField {...params} fullWidth margin="normal" />
              )}
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, 10], // Slight adjustment of the popper's position
                    },
                  },
                ],
                sx: {
                  zIndex: 1500, // Ensure the date picker appears on top of other elements
                },
              }}
              componentsProps={{
                actionBar: {
                  actions: ["clear"], // Enable the clear button in the action bar
                },
              }}
              PaperComponent={({ children }) => (
                <Grid container direction="column">
                  <Grid item>{children}</Grid>
                  <Grid
                    item
                    style={{
                      position: "absolute",
                      bottom: "0px",
                      right: "0px",
                    }}
                  >
                    <Box display="flex" justifyContent="center">
                      {/* Clear button below the date picker */}
                      <Button
                        variant="outlined"
                        startIcon={<Clear />}
                        onClick={() =>
                          setFilters((prevFilters) => {
                            const updatedFilters = {
                              ...prevFilters,
                              ["toDate"]: null,
                            };
                            return updatedFilters;
                          })
                        }
                        sx={{ marginTop: 1, marginBottom: 2 }}
                      >
                        Clear Date
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            />
          </LocalizationProvider>

          {/* Dropdown Fields */}
          <Autocomplete
            //fullWidth
            options={prog}
            getOptionLabel={(option) => option?.name}
            value={prog?.find((p) => p.name === filters?.program)}
            sx={{
              flex: "1 1 calc(50% - 16px)",
            }}
            onChange={(event, newValue) =>
              handleFilterChange("program", newValue?.name)
            }
            clearOnEscape
            clearIcon={filters?.program ? <Clear /> : null} // Shows the clear button only when there is a value
            renderInput={(params) => (
              <TextField
                {...params}
                label="Programs"
                placeholder="Select Program"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true, // Ensures the label stays at the top when there’s a value
                }}
                sx={{
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                  borderRadius: 1,
                  flex: "1 1 calc(50% - 16px)",
                }}
              />
            )}
          />

          <Autocomplete
            //fullWidth
            options={lob}
            getOptionLabel={(option) => option?.name}
            value={lob?.find((p) => p.id === filters?.lob)}
            sx={{
              flex: "1 1 calc(50% - 16px)",
            }}
            onChange={(event, newValue) =>
              handleFilterChange("lob", newValue?.name)
            }
            clearOnEscape
            clearIcon={filters?.lob ? <Clear /> : null} // Shows the clear button only when there is a value
            renderInput={(params) => (
              <TextField
                {...params}
                label="LOB"
                placeholder="Select LOB"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true, // Ensures the label stays at the top when there’s a value
                }}
                sx={{
                  border: (theme) => `1px solid ${theme.palette.primary.main}`,
                  borderRadius: 1,
                  flex: "1 1 calc(50% - 16px)",
                }}
              />
            )}
          />

          {roleId == 9 && (
            <TextField
              label="Coder"
              name="coder"
              value={t[0]?.name}
              disabled
              fullWidth
              margin="normal"
              placeholder="Select Coder"
              sx={{
                border: (theme) => `1px solid ${theme.palette.primary.main}`,
                borderRadius: 1,
                flex: "1 1 calc(50% - 16px)",
              }}
            />
          )}

          {roleId == 8 && (
            <Autocomplete
              //fullWidth
              options={users}
              getOptionLabel={(option) => option?.name}
              value={users?.find((p) => p.id === coder)}
              onChange={(event, newValue) => {
                return setCoder(newValue?.id);
              }}
              sx={{
                flex: "1 1 calc(50% - 16px)",
              }}
              clearOnEscape
              clearIcon={coder ? <Clear /> : null} // Shows the clear button only when there is a value
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Coders"
                  placeholder="Select Coder"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true, // Ensures the label stays at the top when there’s a value
                  }}
                  sx={{
                    border: (theme) =>
                      `1px solid ${theme.palette.primary.main}`,
                    borderRadius: 1,
                    flex: "1 1 calc(50% - 16px)",
                  }}
                />
              )}
            />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            p: 2,
            borderTop: (theme) => `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Button
            variant="contained"
            onClick={() => setGraphFilter(false)}
            sx={{
              ml: 2,
              color: `var(--colorWhite)`,
              bgcolor: (theme) => `${theme.palette.primary.main}`,
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleApplyFilters(filters, false)}
            sx={{
              ml: 2,
              color: `var(--colorWhite)`,
              bgcolor: (theme) => `${theme.palette.primary.main}`,
            }}
          >
            Apply
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleReset}
            sx={{ ml: 2, color: `var(--colorWhite)` }}
          >
            Clear
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default FilterModal;
