import axiosWrapper from "./axiosWrapper";

export const getSchedulerDashboardData = (data) =>
  axiosWrapper.post("/dashboardGraphs/schedulerLeadGraphs", data);

export const getCallLogData = (data) =>
  axiosWrapper.post("/dashboardGraphs/callLogDataCount", data);

export const getQaLeadDashboardData = (data) =>
  axiosWrapper.post("/dashboardGraphs/qaLeadGraphs", data);

export const getCoderLeadDashboardData = (data) =>
  axiosWrapper.post("/dashboardGraphs/codingLeadGraphs", data);

export const getAdminDashboardData = (data) =>
  axiosWrapper.post("/dashboardGraphs/adminGraphs", data);
