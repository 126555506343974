import React, { useEffect, useState } from "react";
import { useAdminData } from "./useAdminData";
import {
  AssignmentTurnedIn,
  CheckCircle,
  MoveToInbox,
  PresentToAll,
} from "@mui/icons-material";
import Loader from "../../../components/Loader/Loader";
import { Grid, Paper, Typography } from "@mui/material";
import AdminPieChart from "../../../components/ChartsGraphs/Admin/AdminPieChart";
import AdminDougnutChart from "../../../components/ChartsGraphs/Admin/AdminDougnutChart";
import TopUsers from "../../../components/ChartsGraphs/Admin/TopUsers";
import "./adminGraphs.css";
import CountUp from "react-countup";
import StatusAvgDays from "../../../components/ChartsGraphs/Coder/StatusAvgDays";

export default function AdminGraphs({
  graphTabId,
  filter,
  sch,
  setGraphFilter,
  setSch,
  setAppliedFilter,
}) {
  const [par, setPar] = useState({});
  const [planId, setPlanId] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    const storedPlanId = localStorage.getItem("clientPlan");
    const storedYear = localStorage.getItem("clientYear");
    setPlanId(storedPlanId || "");
    setYear(storedYear || "");
  }, []);

  useEffect(() => {
    const param = {
      planId: planId || "",
      year: year || "",
      filters: [],
    };
    setPar(param);
    setGraphFilter(false);
  }, [graphTabId]);

  useEffect(() => {
    const param = {
      planId: planId || "",
      year: year || "",
      filters: filter || [],
    };
    if (sch !== "" && sch !== undefined) {
      param["schedulerId"] = sch;
    }

    setPar(param);
    setGraphFilter(false);
  }, [planId, year, filter, setGraphFilter, sch]);

  const { data, loading } = useAdminData(par);
  const {
    schHeader,
    schCallCompleted,
    schCallResult,
    schConnectionRate,
    connectionRate,
    schSchedulingRate,
    schSuccRefData,
    schCancelNoshData,
    qaHeader,
    qaByAssigned,
    qaByReview,
    qaAssignedNotStarted,
    coderHeader,
    coderAssessData,
    codersByAssigned,
    codersByReview,
  } = data ?? {};

  const schHeaderStats = [
    {
      label: "Total Calls Made",
      count: schHeader?.callsCompleted,
      bgColor: "rgba(52, 168, 83, 0.6)", // Soft green (Positive)
      icon: <CheckCircle sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Total Scheduled",
      count: schHeader?.scheduled,
      bgColor: "rgba(0, 123, 255, 0.6)", // Soft blue (Positive)
      icon: <AssignmentTurnedIn sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Total UTC",
      count: schHeader?.utc,
      bgColor: "rgba(255, 90, 95, 0.9)", //"rgba(255, 99, 71, 0.8)", // Soft red (Negative)
      icon: <MoveToInbox sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Total Refused",
      count: schHeader?.refused,
      bgColor: "rgba(58, 0, 204, 0.5)",
      icon: <PresentToAll sx={{ height: "45px", width: "45px" }} />,
    },
  ];

  const qaHeaderStats = [
    {
      label: "Received Members",
      count: qaHeader?.total,
      bgColor: "rgba(52, 168, 83, 0.6)", // Soft green (Positive)
      icon: <CheckCircle sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Completed Members",
      count: qaHeader?.completed,
      bgColor: "rgba(0, 123, 255, 0.6)", // Soft blue (Positive)
      icon: <AssignmentTurnedIn sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Ineligible Members",
      count: qaHeader?.ineligible,
      bgColor: "rgba(255, 90, 95, 0.9)", //"rgba(255, 99, 71, 0.8)", // Soft red (Negative)
      icon: <MoveToInbox sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Assigned Not Started",
      count: qaHeader?.notStarted,
      bgColor: "rgba(58, 0, 204, 0.5)",
      icon: <PresentToAll sx={{ height: "45px", width: "45px" }} />,
    },
  ];

  const codHeaderStats = [
    {
      label: "Members Received",
      count: coderHeader?.total,
      bgColor: "rgba(52, 168, 83, 0.6)", // Soft green (Positive)
      icon: <CheckCircle sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Members Completed",
      count: coderHeader?.completed,
      bgColor: "rgba(0, 123, 255, 0.6)", // Soft blue (Positive)
      icon: <AssignmentTurnedIn sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Ineligible Members",
      count: coderHeader?.ineligible,
      bgColor: "rgba(255, 90, 95, 0.9)", //"rgba(255, 99, 71, 0.8)", // Soft red (Negative)
      icon: <MoveToInbox sx={{ height: "45px", width: "45px" }} />,
    },
    {
      label: "Uploaded Members",
      count: coderHeader?.uploaded,
      bgColor: "rgba(58, 0, 204, 0.5)",
      icon: <PresentToAll sx={{ height: "45px", width: "45px" }} />,
    },
  ];

  const headerStats =
    graphTabId === "Schedulers"
      ? schHeaderStats
      : graphTabId === "QAs"
      ? qaHeaderStats
      : codHeaderStats;
  const HeaderData = () => (
    <Grid item xs={12} className="graph-header-container">
      <Grid container spacing={2}>
        {headerStats.map((stat, index) => (
          <Grid item xs={3} key={index}>
            <Paper
              className="graph-header-item"
              sx={{
                bgcolor: stat.bgColor,
                borderRadius: "8px",
                color: "white",
              }}
            >
              <span className="graph-header-icon">{stat.icon}</span>
              <div className="graph-header-content">
                <Typography
                  variant="h6"
                  sx={{ fontSize: "1.8rem", lineHeight: "0" }}
                >
                  <CountUp start={0} end={stat.count} delay={0} />
                </Typography>
                <Typography sx={{ fontSize: "1.2rem", lineHeight: "0" }}>
                  {stat.label}
                </Typography>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
  const [draggingItem, setDraggingItem] = useState(null);
  const [positions, setPositions] = useState({
    chart1: { order: 1 },
    chart2: { order: 2 },
    chart3: { order: 3 },
    chart4: { order: 4 },
    chart5: { order: 5 },
    chart6: { order: 6 },
    chart7: { order: 7 },
    chart8: { order: 8 },
    chart9: { order: 9 },
    chart10: { order: 10 },
    chart11: { order: 11 },
    chart12: { order: 12 },
  });

  const handleDragStart = (e, chartId) => {
    setDraggingItem(chartId);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Necessary to allow dropping
  };

  const handleDrop = (e, targetChartId) => {
    e.preventDefault();

    // Swap the positions of dragged and target charts
    const updatedPositions = { ...positions };
    const draggedItemOrder = updatedPositions[draggingItem].order;
    updatedPositions[draggingItem].order =
      updatedPositions[targetChartId].order;
    updatedPositions[targetChartId].order = draggedItemOrder;

    setPositions(updatedPositions);
  };

  //Static Data
  const qaByAssigned1 = [
    {
      name: "QA1",
      count: 60,
    },
    {
      name: "QA2",
      count: 48,
    },
    {
      name: "QA3",
      count: 39,
    },
    {
      name: "QA4",
      count: 31,
    },
    {
      name: "QA5",
      count: 24,
    },
    {
      name: "QA6",
      count: 16,
    },
    {
      name: "QA7",
      count: 5,
    },
  ];

  const qaByReview1 = [
    {
      name: "QA1",
      count: 65,
    },
    {
      name: "QA2",
      count: 55,
    },
    {
      name: "QA3",
      count: 49,
    },
    {
      name: "QA4",
      count: 41,
    },
    {
      name: "QA5",
      count: 33,
    },
    {
      name: "QA6",
      count: 21,
    },
    {
      name: "QA7",
      count: 15,
    },
  ];

  const qaAssignedNotStarted1 = [
    {
      name: "Started",
      count: 30,
      color: "#ABCE56",
    },
    {
      name: "Not Started",
      count: 12,
      color: "#FFCE56",
    },
  ];

  const codersByAssigned1 = [
    {
      name: "Coder1",
      count: 53,
    },
    {
      name: "Coder2",
      count: 48,
    },
    {
      name: "Coder3",
      count: 21,
    },
    {
      name: "Coder4",
      count: 12,
    },
    {
      name: "Coder5",
      count: 8,
    },
    {
      name: "Coder6",
      count: 3,
    },
  ];

  const codersByReview1 = [
    {
      name: "Coder1",
      count: 20,
    },
    {
      name: "Coder2",
      count: 15,
    },
    {
      name: "Coder3",
      count: 10,
    },
    {
      name: "Coder4",
      count: 5,
    },
    {
      name: "Coder5",
      count: 2,
    },
  ];

  const coderAssessData1 = [
    {
      name: "Completed",
      count: 700,
      color: "#ABCE56",
    },
    {
      name: "Uploaded",
      count: 600,
      color: "#FFCE56",
    },
  ];

  const schCallCompleted1 = [
    {
      name: "Scheduler1",
      count: 120,
    },
    {
      name: "Scheduler2",
      count: 90,
    },
    {
      name: "Scheduler3",
      count: 77,
    },
    {
      name: "Scheduler4",
      count: 64,
    },
    {
      name: "Scheduler5",
      count: 53,
    },
    {
      name: "Scheduler6",
      count: 39,
    },
    {
      name: "Scheduler7",
      count: 21,
    },
  ];

  const schSchedulingRate1 = [
    {
      name: "scheduled",
      count: 60,
      color: "rgba(0, 255, 0, 0.8)", //'#ABCE56',
    },
    {
      name: "Rescheduled",
      count: 25,
      color: "#ffeb3b", //'#FFCE56',
    },
    {
      name: "Refused",
      count: 11,
      color: "#FF0000", //"#f44336"
    },
  ];

  const colorAssignments = {
    Scheduled: "rgba(0, 255, 0, 0.8)", //"#4caf50", // Positive (Green)
    "No VM Setup": "#808080", // Neutral (Gray)
    "Call Back Requested": "#FFFF00", //"#ffeb3b", // Attention (Bright Yellow)
    "Left Voicemail": "#ff9800", // Caution (Orange)
    "Appointment Cancelled": "rgba(58, 0, 204, 0.5)", // Negative (Purple)
    "Appointment Cancellation": "#FF0000", //"#f44336", // Negative (Red)
    "Dead Air": "#616161", // Neutral (Dark Gray)
    "F2F Call Back": "#009688", // Positive (Teal)
    Hospice: "#80deea", // Soft, Calm (Light Blue)
    Ineligible: "#673ab7", // Negative (Deep Purple)
    "Line Busy": "#FFC0CB", //"#e91e63", // Caution (Pink)
    "Mailbox Full": "#9c27b0", // Neutral (Purple)
    "No Show": "#ff5722", // Negative (Dark Orange)
    Rescheduled: "#03a9f4", // Positive (Bright Blue)
    Refusal: "rgba(58, 0, 204, 1)",
  };

  const schCallResult1 = [
    {
      name: "Scheduled",
      count: 45,
      color: colorAssignments["Scheduled"],
    },
    {
      name: "Call Back Requested",
      count: 21,
      color: colorAssignments["Call Back Requested"],
    },
    {
      name: "Appointment Cancellation",
      count: 7,
      color: colorAssignments["Appointment Cancellation"],
    },
    {
      name: "F2F Call Back",
      count: 12,
      color: colorAssignments["F2F Call Back"],
    },
    {
      name: "Refusal",
      count: 11,
      color: colorAssignments["Refusal"],
    },
    {
      name: "Ineligible",
      count: 5,
      color: colorAssignments["Ineligible"],
    },
  ];

  const schConnectionRate1 = [
    {
      name: "Scheduled",
      count: 40,
      color: "rgba(0, 255, 0, 0.8)", //"#4caf50"
    },
    {
      name: "Rescheduled",
      count: 12,
      color: "#03a9f4",
    },
    {
      name: "Call Backs Requested",
      count: 15,
      color: "#ffeb3b",
    },
    {
      name: "Refused",
      count: 11,
      color: "rgba(58, 0, 204, 1)",
    },
    {
      name: "No Shows",
      count: 5,
      color: "#ff5722",
    },
  ];

  const schSuccRefData1 = [
    {
      name: "Success",
      count: Math.ceil(70),
      color: "#304d6d",
    },
    {
      name: "Refusal",
      count: Math.ceil(30),
      color: "#82a0bc",
    },
  ];

  const schCancelNoshData1 = [
    {
      name: "Cancellation",
      count: 16,
      color: "#FF0000", //"#304d6d"
    },
    {
      name: "No Shows",
      count: 5,
      color: "#FFC0CB", //"#82a0bc"
    },
  ];

  const avgDaysData1 = {
    readyForQa: { count: 30 },
    clinReview: { count: 40 },
    readyForUpload: { count: 20 },
  };

  const avgDaysData2 = {
    readyForQa: {
      days: 4,
      count: 20,
    },
    clinReview: {
      days: 3,
      count: 15,
    },
  };

  return (
    <>
      {!data ? (
        <Loader />
      ) : (
        <Grid
          container
          spacing={2}
          className="graph-dashboard-container"
          sx={{ paddingBottom: "30px", marginTop: 0 }}
        >
          <Grid container item xs={12} spacing={2}>
            {!loading && <HeaderData />}

            {graphTabId === "QAs" && (
              <>
                <Grid
                  item
                  xs={12}
                  md={4.5}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart1")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart1")}
                  style={{ order: positions.chart1.order }}
                >
                  <Paper className="top-mem-agn" sx={{ borderRadius: "8px" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        height: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Top QAs by Members Assigned
                    </Typography>
                    <TopUsers data={qaByAssigned} />
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4.5}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart2")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart2")}
                  style={{ order: positions.chart2.order }}
                >
                  <Paper className="top-mem-agn" sx={{ borderRadius: "8px" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        height: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Top QAs by Review Count
                    </Typography>
                    <TopUsers data={qaByReview} />
                  </Paper>
                </Grid>

                {/* <Grid
                  item
                  xs={12}
                  md={3}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart2")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart2")}
                  style={{ order: positions.chart2.order }}
                >
                  <Paper
                    className="chart-item status-days"
                    sx={{ borderRadius: "8px" }}
                  >
                    <Typography variant="h6">
                      All QA's Status Days Breakdown
                    </Typography>
                    <StatusAvgDays data={avgDaysData2} />
                  </Paper>
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  md={3}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart3")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart3")}
                  style={{ order: positions.chart3.order }}
                >
                  <Paper
                    className="chart-item status-days"
                    sx={{ borderRadius: "8px" }}
                  >
                    <Typography variant="h6">
                      Members Assigned Breakdown
                    </Typography>
                    <AdminDougnutChart data={qaAssignedNotStarted} />
                  </Paper>
                </Grid>
              </>
            )}

            {graphTabId === "Coders" && (
              <>
                <Grid
                  item
                  xs={12}
                  md={4.5}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart10")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart10")}
                  style={{ order: positions.chart10.order }}
                >
                  <Paper className="top-mem-agn" sx={{ borderRadius: "8px" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        height: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Top Coders by Members Assigned
                    </Typography>
                    <TopUsers data={codersByAssigned} />
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={4.5}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart11")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart11")}
                  style={{ order: positions.chart11.order }}
                >
                  <Paper className="top-mem-agn" sx={{ borderRadius: "8px" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        height: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Top Coders by Review
                    </Typography>
                    <TopUsers data={codersByReview} />
                  </Paper>
                </Grid>

                {/* <Grid
                  item
                  xs={12}
                  md={3}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart2")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart2")}
                  style={{ order: positions.chart2.order }}
                >
                  <Paper
                    className="chart-item status-days"
                    sx={{ borderRadius: "8px" }}
                  >
                    <Typography variant="h6">
                      All Coders Status Days Breakdown
                    </Typography>
                    <StatusAvgDays data={avgDaysData1} />
                  </Paper>
                </Grid> */}

                <Grid
                  item
                  xs={12}
                  md={3}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart12")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart12")}
                  style={{ order: positions.chart12.order }}
                >
                  <Paper
                    className="chart-item status-days"
                    sx={{ borderRadius: "8px" }}
                  >
                    <Typography variant="h6">
                      Members Assessment Status
                    </Typography>
                    <AdminDougnutChart data={coderAssessData} />
                  </Paper>
                </Grid>
              </>
            )}

            {graphTabId === "Schedulers" && (
              <>
                <Grid
                  item
                  xs={12}
                  md={6}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart4")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart4")}
                  style={{ order: positions.chart4.order }}
                >
                  <Paper className="top-mem-agn" sx={{ borderRadius: "8px" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        height: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      Top Schedulers by Call Attempts
                    </Typography>
                    <TopUsers data={schCallCompleted} />
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart7")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart7")}
                  style={{ order: positions.chart7.order }}
                >
                  <Paper
                    className="chart-item status-days"
                    sx={{ borderRadius: "8px" }}
                  >
                    <Typography variant="h6">
                      Scheduling Connection Rate
                    </Typography>
                    <AdminPieChart data={schConnectionRate} />
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart6")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart6")}
                  style={{ order: positions.chart6.order }}
                >
                  <Paper
                    className="chart-item status-days"
                    sx={{ borderRadius: "8px" }}
                  >
                    <Typography variant="h6">Call Results Breakdown</Typography>
                    <AdminPieChart data={schCallResult} />
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart5")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart5")}
                  style={{ order: positions.chart5.order }}
                >
                  <Paper
                    className="chart-item status-days"
                    sx={{ borderRadius: "8px" }}
                  >
                    <Typography variant="h6">
                      Scheduled & Refused Calls
                    </Typography>
                    <AdminPieChart data={schSchedulingRate} />
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart8")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart8")}
                  style={{ order: positions.chart8.order }}
                >
                  <Paper
                    className="chart-item status-days"
                    sx={{ borderRadius: "8px" }}
                  >
                    <Typography variant="h6">
                      Calls Success & Refusal Rate
                    </Typography>
                    <AdminDougnutChart data={schSuccRefData} />
                  </Paper>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  draggable
                  onDragStart={(e) => handleDragStart(e, "chart9")}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, "chart9")}
                  style={{ order: positions.chart9.order }}
                >
                  <Paper
                    className="chart-item status-days"
                    sx={{ borderRadius: "8px" }}
                  >
                    <Typography variant="h6">
                      Appointment Cancellation & Noshows
                    </Typography>
                    <AdminDougnutChart data={schCancelNoshData} />
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
      {/* {loading && <Loader/>} */}
    </>
  );
}
