import React from "react";
import { Doughnut } from "react-chartjs-2";

const AdminDougnutChart = ({ data }) => {
  const val = data?.map((dt) => dt.count);
  if (val?.every((count) => count === 0)) return <h3>No Available Data</h3>;
  const lab = data?.map((dt) => dt.name);
  const col = data?.map((dt) => dt.color);
  const barData = {
    labels: lab, //data?.utc ? ['UTC', 'Line Busy'] : ['Assessment Completed', 'Assessment Uploaded'],
    datasets: [
      {
        data: val,
        backgroundColor: col,
        hoverBackgroundColor: col, //["#304d6d", "#82a0bc"],
        borderWidth: 0,
        spacing: 0, // Remove white space between pie slices
        radius: "70%", // Reduce the overall radius of the pie chart
        hoverOffset: 20, // Pop out on hover
      },
    ],
  };

  const options = {
    responsive: true,
    cutout: "70%",
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return data[0]?.name === "Success"
              ? `${tooltipItem.label}: ${tooltipItem.raw}%`
              : `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };
  return <Doughnut data={barData} options={options} />;
};

export default AdminDougnutChart;
