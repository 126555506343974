import React from "react";
import { Bar, Scatter } from "react-chartjs-2";

const TopUsers = ({ data }) => {
  if (data?.length === 0) return <h3>No Available Data</h3>;
  // const qaMapping = data?.reduce((acc, qa, index) => {
  //     acc[qa.name] = index + 1;
  //     return acc;
  // }, {});
  // const scatterData = {
  //     datasets: [
  //       {
  //         label: 'Top QA by Members Assigned',
  //         data: data?.map((qa) => ({
  //             x: qaMapping[qa.name], // Numeric position on x-axis
  //             y: parseInt(qa.count), // Calls completed on y-axis
  //           })),
  //         backgroundColor: 'rgba(75,192,192,1)',
  //         pointRadius: 12,
  //       },
  //     ],
  // };
  // const options = {
  //     responsive: true,
  //     plugins: {
  //       title: {
  //         display: false,
  //       },
  //       legend: {
  //         display: false, // Since all points are the same color, no need for a legend
  //       },
  //     },
  //     scales: {
  //       x: {
  //         type: "category", // Use category scale for categorical x-axis
  //         position: "bottom",
  //         labels: data?.map((qa) => qa.name), // Use scheduler names as labels
  //         title: {
  //           display: false,
  //           text: "QAs",
  //         },
  //         ticks: {
  //           autoSkip: false, // Ensure all labels are shown
  //         },
  //       },
  //       y: {
  //         beginAtZero: true,
  //         title: {
  //           display: false,
  //           text: "Members Assigned",
  //         },
  //       },
  //     },
  // };
  // Mapping QA names to their index positions
  const qaMapping = data?.reduce((acc, qa, index) => {
    acc[qa.name] = index + 1;
    return acc;
  }, {});

  // Prepare bar chart data for the horizontal layout
  const barChartData = {
    labels: data?.map((qa) => qa.name), // Labels for each QA
    datasets: [
      {
        //label: 'Top QA by Members Assigned',
        data: data?.map((qa) => parseInt(qa.count)), // Count values for the y-axis
        backgroundColor: "#4caf50", //'rgba(75, 192, 192, 0.6)',
        borderColor: "#4caf50", //'rgba(75, 192, 192, 1)',
        borderWidth: 0,
        barThickness: 20,
      },
    ],
  };

  // Chart options to make it horizontal
  const options = {
    //indexAxis: 'y', // Makes the chart horizontal
    scales: {
      x: {
        beginAtZero: true, // Start the x-axis at zero
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Top QA by Members Assigned",
      },
    },
  };
  return <Bar data={barChartData} options={options} />;
};

export default TopUsers;
