// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-container {
  padding: 16px;
  background-color: #ffffff;
  /* border-radius: 0px 0px 8px 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.chart-container canvas {
  height: 100%;
  width: 100% !important;
}

.status-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc; /* Optional: Adds a border under the statuses */
  padding-bottom: 10px;
  font-size: 11px;
}

.status-item {
  display: inline-block;
  margin-right: 20px;
}

.status-container::-webkit-scrollbar,
.status-container1::-webkit-scrollbar {
  display: none;
}

.status-container:hover::-webkit-scrollbar,
.status-container1:hover::-webkit-scrollbar {
  display: inline;
}

.status-container1 {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 10px;
  border-top: 1px solid #ccc; /* Optional: Adds a border under the statuses */
  padding-top: 10px;
  font-size: 11px;
}
/* .status-container {
  width: 100%;
  position: relative;
} */

.status-wrapper {
  display: inline-block;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChartsGraphs/hraChart.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB;6CAC2C;AAC7C;;AAEA;EACE,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B,EAAE,+CAA+C;EAC9E,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;;EAEE,aAAa;AACf;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,0BAA0B,EAAE,+CAA+C;EAC3E,iBAAiB;EACjB,eAAe;AACjB;AACA;;;GAGG;;AAEH;EACE,qBAAqB;AACvB","sourcesContent":[".chart-container {\r\n  padding: 16px;\r\n  background-color: #ffffff;\r\n  /* border-radius: 0px 0px 8px 8px;\r\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */\r\n}\r\n\r\n.chart-container canvas {\r\n  height: 100%;\r\n  width: 100% !important;\r\n}\r\n\r\n.status-container {\r\n  display: flex;\r\n  overflow-x: auto;\r\n  white-space: nowrap;\r\n  margin-bottom: 10px;\r\n  border-bottom: 1px solid #ccc; /* Optional: Adds a border under the statuses */\r\n  padding-bottom: 10px;\r\n  font-size: 11px;\r\n}\r\n\r\n.status-item {\r\n  display: inline-block;\r\n  margin-right: 20px;\r\n}\r\n\r\n.status-container::-webkit-scrollbar,\r\n.status-container1::-webkit-scrollbar {\r\n  display: none;\r\n}\r\n\r\n.status-container:hover::-webkit-scrollbar,\r\n.status-container1:hover::-webkit-scrollbar {\r\n  display: inline;\r\n}\r\n\r\n.status-container1 {\r\n  display: flex;\r\n  overflow-x: auto;\r\n  white-space: nowrap;\r\n  margin-top: 10px;\r\n  border-top: 1px solid #ccc; /* Optional: Adds a border under the statuses */\r\n  padding-top: 10px;\r\n  font-size: 11px;\r\n}\r\n/* .status-container {\r\n  width: 100%;\r\n  position: relative;\r\n} */\r\n\r\n.status-wrapper {\r\n  display: inline-block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
