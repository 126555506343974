import { Grid } from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";
import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import DFRadio from "../../DFInputs/DFRadio";
import { DFSelect } from "../../DFInputs/DFSelect";
import DFText from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  DynamicTablePreventiveCare,
  DynamicTablePreventiveCare2024,
} from "../../DFInputs/DFDataTable";
import { staticData } from "../../../../DynamicFormMUI/indexData";
import { TableComponent } from "../../../../DynamicFormMUI/staticForm";
import {
  getPreventiveData,
  setPreventiveData,
} from "../../Sections/preventiveCare/preventiveMapping";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./preventiveFormData";
import { useLocation } from "react-router-dom";
import { getApi } from "../../../../../../utils/axiosApiWrapper";

const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const PreventiveCare = forwardRef((props, _ref) => {
  const {
    // DemographicsFormData,
    // setDemographicsFormData,
    // showComment,
    // setShowComment,
    getSectionInputsData,
    // getStateValue,
    // setStateValue,
    getDisableFlag,
  } = useFillHraContext();
  const { storedPlan, storedYear, setSideBarSectionDisable } = useUserContext();
  const location = useLocation();
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const roleId = sessionStorage.getItem("roleId");
  const [preventiveFormData, setPreventiveFormData] = useState({});
  const [preventivesRowData, setPreventivesRowData] = useState([]);
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const [loading, setLoading] = useState(undefined);
  const sectionId = props.currSection || 8;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);

  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const defaultScreenings2023 = [
    "Breast Cancer Screening",
    "Colorectal Screening",
    "Influenza Vaccine",
    "COVID-19 Vaccine",
    "Pneumococcal Vaccine",
    "Herpes Zoster Vaccine",
    "Diabetes Screening",
    "Diabetic Foot Exam",
    "Cholesterol Screening",
    "Glaucoma Screening",
    "STIs/HIV Screening",
    "Cervical Cancer Screening",
    "Osteoporosis Screening",
    "Prostate Screening",
    "Fall Risk Screening",
  ];

  const defaultScreenings2024 = [
    "Breast Cancer Screening",
    "Cervical Cancer Screening",
    "Cholesterol Screening",
    "Colorectal Screening",
    "COVID-19 Vaccine",
    "Diabetic Eye Exam",
    "Diabetic Foot Exam",
    "Diabetes Screening",
    // "Fall Risk Screening",
    "Herpes Zoster Vaccine",
    "Influenza Vaccine",
    "Osteoporosis Screening",
    "Pneumococcal Vaccine",
    "Prostate Screening",
    "STIs/HIV Screening",
  ];
  const screeningsWithMethod2023 = [
    "Breast Cancer Screening",
    "COVID-19 Vaccine",
    "Cervical Cancer Screening",
  ];
  const screeningsWithMethod2024 = [
    "Breast Cancer Screening",
    "Colorectal Screening",
    "Osteoporosis Screening",
  ];

  const currentYearScreeningsWithMethod =
    clientYear === "2023" ? screeningsWithMethod2023 : screeningsWithMethod2024;
  const defaultScreenings =
    clientYear === "2023" ? defaultScreenings2023 : defaultScreenings2024;

  const initialpreventivesRowData =
    clientYear === "2023"
      ? Array.from({ length: 15 }, (_, index) => ({
          screen: defaultScreenings[index] || "",
          answer: "",
          date: "",
          method: currentYearScreeningsWithMethod.includes(
            defaultScreenings[index],
          )
            ? ""
            : "N/A",
          recommendation: "0",
          educationCompleted: "0",
        }))
      : Array.from({ length: 14 }, (_, index) => ({
          screen: defaultScreenings[index] || "",
          answer: "0",
          recommendation: "0",
        }));

  // const initialpreventivesRowData = Array.from({ length: 15 }, (_, index) => ({
  //   screen: defaultScreenings[index] || "",
  //   answer: "",
  //   date: "",
  //   // method: "",
  //   method: currentYearScreeningsWithMethod.includes(defaultScreenings[index])
  //     ? ""
  //     : "N/A",
  //   recommendation: "0",
  //   educationCompleted: "0",
  // }));

  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);
      // const dbData = await props.getSectionDbData(sectionId);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];
      const mashedData =
        dbData?.data?.preventive?.data &&
        Object.keys(dbData?.data?.preventive?.data).length > 0
          ? getPreventiveData(dbData?.data?.preventive?.data)
          : {};

      // let tableData1 =
      //   mashedData?.preventiveDatatableVal ||
      //   initialpreventivesRowData.map((item, index) => ({
      //     ...item,
      //     method: currentYearScreeningsWithMethod.includes(
      //       defaultScreenings[index],
      //     )
      //       ? item.method || ""
      //       : "N/A",
      //   }));
      let tableData1 =
        (clientYear === "2024" && mashedData?.preventiveDatatableVal
          ? mashedData.preventiveDatatableVal.slice(0, 14)
          : mashedData?.preventiveDatatableVal) ||
        (clientYear === "2024"
          ? initialpreventivesRowData.slice(0, 14).map((item, index) => ({
              ...item,
              // method: currentYearScreeningsWithMethod.includes(defaultScreenings[index])
              //   ? item.method || ""
              //   : "N/A",
            }))
          : initialpreventivesRowData.map((item, index) => ({
              ...item,
              method: currentYearScreeningsWithMethod.includes(
                defaultScreenings[index],
              )
                ? item.method || ""
                : "N/A",
            })));

      if (tableData1.length === 0) {
        tableData1 = initialpreventivesRowData;
      }

      const commentVisibility = calcCommentVisibility(mashedData);
      let mashedCalcData = {
        ...mashedData,
        ...commentVisibility,
      };

      setPreventiveFormData(mashedCalcData);
      setPreventivesRowData(tableData1);
      setLoading(false);
      setSideBarSectionDisable(false);
    }

    getDbData();
    setDisableFlag(getDisableFlag());
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...preventiveFormData };
    updatedFormData[name] = value;

    setPreventiveFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...preventiveFormData };
    updatedFormData[name] = value;

    setPreventiveFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...preventiveFormData };
    updatedFormData[name] = value;

    setPreventiveFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...preventiveFormData };
    updatedFormData[name] = value;

    setPreventiveFormData(updatedFormData);
  };

  const handleOptionsTreeChange = (name, value, isMulti) => {
    /* togglebutton gruop type -> scenario -> actual behaviour -> our pattern

    <- multi ->
        pass values -> array -> array
        if value selected -> array -> array

    <- single -> 

        pass values -> string -> always pass as array
        if value selected -> string -> always change to array and set back to state

    */
    const updatedFormData = { ...preventiveFormData };
    let oldSelection = updatedFormData[name];
    let newSelection = [];
    if (name === "oneTimeScreenForAbdominal") {
      updatedFormData.oneTimeScreenForAbdominaleducationProvided = "";
      updatedFormData.oneTimeScreenForAbdominaleducationProvidedComment = "";
    }
    if (name === "oneTimeScreenForHepatitis") {
      updatedFormData.oneTimeScreenForHepatitiseducationProvided = "";
      updatedFormData.oneTimeScreenForHepatitiseducationProvidedComment = "";
    }
    setPreventiveFormData(updatedFormData);
    if (isMulti) {
      newSelection = value;
    } else {
      if (!oldSelection?.includes(value)) {
        newSelection.push(value);
      }
    }

    updatedFormData[name] = newSelection;
    setPreventiveFormData(updatedFormData);
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...preventiveFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setPreventiveFormData(updatedFormData);
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      const formData = preventiveFormData;
      const tableData1 = preventivesRowData || [];
      const updatedTableData1 = tableData1.map((item, index) => ({
        ...item,
        method: currentYearScreeningsWithMethod.includes(
          defaultScreenings[index],
        )
          ? item.method || ""
          : "N/A",
      }));

      let combinedData = {
        ...formData,
        preventiveDatatableVal: updatedTableData1,
      };
      let hashedData = setPreventiveData(combinedData);
      return hashedData;
    },
  }));

  useEffect(() => {
    const formData = preventiveFormData;
    const tableData1 = preventivesRowData || [];
    const updatedTableData1 = tableData1.map((item, index) => ({
      ...item,
      method: currentYearScreeningsWithMethod.includes(defaultScreenings[index])
        ? item.method || ""
        : "N/A",
    }));

    let combinedData = {
      ...formData,
      preventiveDatatableVal: updatedTableData1,
    };

    props.setAutoSavePreventiveCareData({ ...combinedData });
  }, [preventiveFormData, preventivesRowData]);

  const getFieldValues = useCallback(
    (name) => {
      return preventiveFormData[name];
    },
    [preventiveFormData],
  );

  const setFieldValues = useCallback((name, value, type = "") => {
    setPreventiveFormData((prevData) => {
      let tempData = { ...prevData };
      if (type === "datatable") {
        tempData = {
          ...tempData,
          ...value,
        };
      }
      tempData = {
        ...tempData,
        [name]: value,
      };

      return { ...tempData };
    });
  }, []);

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={loading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        style={{
          margin: "10px 20px",
        }}
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const { questionId, name, xs, type, isSingleButton } = inputProps;
            const inputValue = preventiveFormData[name] || "";
            const commentValue = `${
              preventiveFormData?.[`${name}Comment`]
                ? `${preventiveFormData[`${name}Comment`]}`
                : ""
            }`;
            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFText
                    inputProps={inputProps}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "datatable") {
              const selectedTable = inputProps.tablelist.filter(
                (table) => table && table.length > 0 && table[1] === true,
              );
              return (
                <Grid key={quesSlug} xs={inputProps.xs} item>
                  <div style={{ textAlign: "center" }}>
                    {selectedTable &&
                      selectedTable.length > 0 &&
                      selectedTable.map((table, index) => {
                        if (table[0].label === "Preventive Care") {
                          return clientYear === "2024" ? (
                            <DynamicTablePreventiveCare2024
                              preventivesRowData={preventivesRowData}
                              setPreventivesRowData={setPreventivesRowData}
                              disableFlag={
                                disableFlag || (roleId == 5 && tabId == 3)
                              }
                            />
                          ) : (
                            <DynamicTablePreventiveCare
                              preventivesRowData={preventivesRowData}
                              setPreventivesRowData={setPreventivesRowData}
                              disableFlag={
                                disableFlag || (roleId == 5 && tabId == 3)
                              }
                            />
                          );
                        }
                      })}
                  </div>
                </Grid>
              );
            } else if (type === "radio") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFRadio
                    inputProps={inputProps}
                    value={inputValue}
                    handleRadioChange={handleRadioChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = preventiveFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDate
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                    // dtPickProps={{
                    //   disableFuture:true,
                    //   disablePast:true,
                    //   minDate:minDOB,
                    //   maxDate:maxDOB,
                    //   views:["year", "month", "day"]
                    // }}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFSelect
                    inputProps={inputProps}
                    value={inputValue}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              return (
                <DFOptionsTree
                  inputProps={inputProps}
                  formData={preventiveFormData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  setFormData={setPreventiveFormData}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  // commentValue={commentValue}
                  disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              );
            } else if (type === "label") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFLabel
                    inputProps={inputProps}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={{
                      ...inputProps,
                      disabled: roleId == 5 && tabId == 3,
                    }}
                    handleButtonClick={handleButtonClick}
                    // disableFlag={disableFlag} - check & implement
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeading inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              return (
                <Grid xs={xs} item>
                  <DFCheckbox
                    inputProps={inputProps}
                    showComment={showComment}
                    handleCheckboxChange={handleCheckboxChange}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    setShowComment={setShowComment}
                    quesSlug={quesSlug}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag || (roleId == 5 && tabId == 3)}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFImage inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "divider") {
              return <DFRuler />;
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default PreventiveCare;
